/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, NavDropdown } from 'react-bootstrap';
import {
  changeLocale,
  FormattedMessage,
  IntlContextConsumer,
  Link as LinkIntl,
  navigate,
  useIntl,
} from 'gatsby-plugin-intl';

import './Header.css';
import Logo from '../../images/logo.svg';
import { graphql, useStaticQuery } from 'gatsby';

const Header = (props) => {
  const intl = useIntl();
  const [headerScrolled, setHeaderScrolled] = useState(false);
  const [mobileHeaderVisible, setMobileHeaderVisible] = useState(false);

  const languageName = {
    en: 'English',
    de: 'Deutsch',
  };

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { fields: [frontmatter___meta___order], order: ASC }
        filter: { fileAbsolutePath: { regex: "/(content/pages/services)/" } }
      ) {
        nodes {
          frontmatter {
            meta {
              slug
              name
              order
            }
          }
          fields {
            language
          }
        }
      }
    }
  `);
  const menuEntries = data.allMarkdownRemark.nodes.filter((element) => element.fields.language === intl.locale);

  const scrollHandler = useCallback((headerScrolled, setHeaderScrolled) => {
    const contentScrolled = window.pageYOffset > 50;
    if (contentScrolled !== headerScrolled) {
      setHeaderScrolled(contentScrolled);
    }
  }, []);

  useEffect(() => {
    const handler = () => scrollHandler(headerScrolled, setHeaderScrolled);
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [headerScrolled, setHeaderScrolled, scrollHandler]);

  const navigateIntlHandler = (url) => navigate(url);

  const mobileHeader = (
    <div className="nav-mobile d-lg-none">
      <div>
        <div className="close-container">
          <button type="button" className="close" aria-label="Close" onClick={() => setMobileHeaderVisible(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div className="nav-content">
        <ul>
          <li>
            <LinkIntl to="/index.html#landing" onClick={() => setMobileHeaderVisible(false)}>
              <FormattedMessage id="menu.home" />
            </LinkIntl>
          </li>
          <li>
            <LinkIntl to="/blog/index.html" onClick={() => setMobileHeaderVisible(false)}>
              <FormattedMessage id="menu.blog" />
            </LinkIntl>
          </li>
          <li>
            <LinkIntl to="/index.html#services" onClick={() => setMobileHeaderVisible(false)}>
              <FormattedMessage id="menu.services" />
            </LinkIntl>
          </li>
          <li>
            <LinkIntl to="/about/index.html" onClick={() => setMobileHeaderVisible(false)}>
              <FormattedMessage id="menu.about" />
            </LinkIntl>
          </li>
          <li>
            <LinkIntl to="/index.html#testimonials" onClick={() => setMobileHeaderVisible(false)}>
              <FormattedMessage id="menu.clients" />
            </LinkIntl>
          </li>
          <li className="mb-3">
            <IntlContextConsumer>
              {({ languages, language: currentLocale }) =>
                languages
                  .filter((lang) => lang !== currentLocale)
                  .map((language) => (
                    <a href="#" key={language} onClick={() => changeLocale(language, '/index.html#landing')}>
                      {languageName[language]}
                    </a>
                  ))
              }
            </IntlContextConsumer>
          </li>
          <li>
            <LinkIntl to="/contact/index.html">
              <Button
                size="lg"
                variant="light"
                style={{
                  color: 'white',
                  backgroundColor: '#009688',
                  minWidth: '230px',
                  minHeight: '48px',
                  borderRadius: '8px',
                  fontSize: '18px',
                  transform: 'translateY(-10px)',
                }}
              >
                <FormattedMessage id="buttons.contact_us" />
              </Button>
            </LinkIntl>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <>
      <header id="header" className={headerScrolled || !props.accentHeader ? 'header-scrolled' : ''}>
        <Container fluid className="header-container">
          <div className="navbar-toggler-container">
            <button
              type="button"
              className="navbar-toggler d-lg-none"
              aria-label="Toggle navigation"
              onClick={() => setMobileHeaderVisible(!mobileHeaderVisible)}
            >
              <span className="navbar-toggler-icon" />
            </button>
          </div>

          <div id="logo-container" className={!headerScrolled && props.accentHeader ? 'logo-hidden' : ''}>
            <LinkIntl to="/index.html#landing">
              <img src={Logo} alt="Bebensee IT Logo" title="Home" height="32px" />
            </LinkIntl>
          </div>

          <nav id="main-nav">
            <ul
              id="header-links"
              className={headerScrolled || !props.accentHeader ? 'nav-menu-scrolled nav-menu' : 'nav-menu'}
            >
              <li className="d-none d-lg-block">
                <LinkIntl to="/index.html#landing">
                  <FormattedMessage id="menu.home" />
                </LinkIntl>
              </li>
              <li className="d-none d-lg-block">
                <LinkIntl to="/blog/index.html">
                  <FormattedMessage id="menu.blog" />
                </LinkIntl>
              </li>
              <li className="d-none d-lg-block">
                <NavDropdown id="services-dropdown" title={intl.formatMessage({ id: 'menu.services' })}>
                  {menuEntries.map((service) => (
                    <NavDropdown.Item
                      key={service.frontmatter.meta.slug}
                      onSelect={() => navigateIntlHandler(`/services/${service.frontmatter.meta.slug}/index.html`)}
                    >
                      <LinkIntl to={`/services/${service.frontmatter.meta.slug}/index.html`}>
                        {service.frontmatter.meta.name}
                      </LinkIntl>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </li>
              <li className="d-none d-lg-block">
                <LinkIntl to="/about/index.html">
                  <FormattedMessage id="menu.about" />
                </LinkIntl>
              </li>
              <li className="d-none d-xl-block">
                <LinkIntl to="/index.html#testimonials">
                  <FormattedMessage id="menu.clients" />
                </LinkIntl>
              </li>
              <li className="d-none d-md-block">
                <IntlContextConsumer>
                  {({ languages, language: currentLocale }) =>
                    languages
                      .filter((language) => language !== currentLocale)
                      .map((language) => (
                        <a href="#" key={language} onClick={() => changeLocale(language, '/index.html#landing')}>
                          {languageName[language]}
                        </a>
                      ))
                  }
                </IntlContextConsumer>
              </li>
              <li className="d-none d-md-block">
                <LinkIntl to="/contact/index.html">
                  <Button
                    size="lg"
                    variant="light"
                    style={{
                      color: 'white',
                      backgroundColor: '#009688',
                      minWidth: '230px',
                      minHeight: '48px',
                      borderRadius: '8px',
                      fontSize: '18px',
                      transform: 'translateY(-10px)',
                    }}
                  >
                    <FormattedMessage id="buttons.contact_us" />
                  </Button>
                </LinkIntl>
              </li>
            </ul>
          </nav>
        </Container>
      </header>

      {mobileHeaderVisible && mobileHeader}
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  accentHeader: PropTypes.bool,
};

export default Header;
