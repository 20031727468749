/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormCheck,
  Accordion,
  Card,
  Table,
  useAccordionToggle,
  AccordionContext,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { changeLocale, FormattedMessage, IntlContextConsumer } from 'gatsby-plugin-intl';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CookieConsentModal.css';

function ContextAwareToggle({ eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button className={'button button-text'} onClick={decoratedOnClick}>
      {isCurrentEventKey ? (
        <>
          <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: '0.3rem' }} />
          <FormattedMessage id="cookies.hide-cookies-information" />
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: '0.3rem' }} />
          <FormattedMessage id="cookies.show-cookies-information" />
        </>
      )}
    </button>
  );
}

const CookieConsentModal = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [enableAnalytics, setEnableAnalytics] = useState(false);
  const languageName = {
    en: 'English',
    de: 'Deutsch',
  };

  return (
    <Modal centered show={props.show} scrollable>
      <Modal.Header>
        <Modal.Title className="modal-title">
          <FormattedMessage id="cookies.preferences" />
        </Modal.Title>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages
              .filter((lang) => lang !== currentLocale)
              .map((language) => (
                <a href="#" key={language} onClick={() => changeLocale(language, '/index.html#landing')}>
                  {languageName[language]}
                </a>
              ))
          }
        </IntlContextConsumer>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
        >
          <div>
            <FormattedMessage id="cookies.text" />
            <IntlContextConsumer>
              {({ languages, language: currentLocale }) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/${currentLocale}/data-privacy/index.html`}
                  style={{
                    paddingLeft: '0.3rem',
                    textDecoration: 'underline',
                  }}
                >
                  <FormattedMessage id="cookies.privacy_policy" />.
                </a>
              )}
            </IntlContextConsumer>
          </div>
          <div>
            {!showOptions ? (
              <button className="button-text" onClick={() => setShowOptions(true)}>
                <FontAwesomeIcon icon={faChevronDown} />
              </button>
            ) : (
              <button className="button-text" onClick={() => setShowOptions(false)}>
                <FontAwesomeIcon icon={faChevronUp} />
              </button>
            )}
          </div>
        </div>

        {!showOptions && (
          <Form className={'form-container'}>
            <FormGroup>
              <FormCheck disabled={true}>
                <FormCheck.Input type="checkbox" id="necessary" checked={true} />
                <FormCheck.Label htmlFor="necessary" style={{ paddingLeft: '0.5em' }}>
                  <FormattedMessage id={'cookies.necessary'} />
                </FormCheck.Label>
              </FormCheck>
            </FormGroup>
            <FormGroup>
              <FormCheck>
                <FormCheck.Input
                  type="checkbox"
                  id="analytics"
                  required={true}
                  checked={enableAnalytics}
                  onChange={(e) => setEnableAnalytics(e.target.checked)}
                />
                <FormCheck.Label htmlFor="analytics" style={{ paddingLeft: '0.5em' }}>
                  <FormattedMessage id={'cookies.usage-analysis'} />
                </FormCheck.Label>
              </FormCheck>
            </FormGroup>
          </Form>
        )}

        {showOptions && (
          <>
            <Accordion>
              <Card>
                <Card.Header>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormattedMessage id={'cookies.necessary'} />
                    <Form.Check aria-label="cookies.necessary" disabled checked={true} />
                  </div>
                  <FormattedMessage id={'cookies.necessary-text'} />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ContextAwareToggle eventKey="0" />
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id={'cookies.cookie-name'} />
                          </th>
                          <th>
                            <FormattedMessage id={'cookies.provider'} />
                          </th>
                          <th>
                            <FormattedMessage id={'cookies.purpose'} />
                          </th>
                          <th>
                            <FormattedMessage id={'cookies.duration'} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>gatsby-gdpr-google-analytics</td>
                          <td>Bebensee IT GmbH</td>
                          <td>
                            <FormattedMessage id={'cookies.storage-privacy'} />
                          </td>
                          <td>
                            1 <FormattedMessage id={'cookies.year'} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormattedMessage id={'cookies.usage-analysis'} />
                    <Form.Check
                      type="checkbox"
                      id="analytics"
                      required={true}
                      checked={enableAnalytics}
                      onChange={(e) => setEnableAnalytics(e.target.checked)}
                    />
                  </div>
                  <FormattedMessage id={'cookies.usage-analysis-text'} />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ContextAwareToggle eventKey="1" />
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id={'cookies.cookie-name'} />
                          </th>
                          <th>
                            <FormattedMessage id={'cookies.provider'} />
                          </th>
                          <th>
                            <FormattedMessage id={'cookies.purpose'} />
                          </th>
                          <th>
                            <FormattedMessage id={'cookies.duration'} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>_ga</td>
                          <td>Google Ireland Limited</td>
                          <td>
                            <FormattedMessage id={'cookies.analysis'} />
                          </td>
                          <td>
                            2 <FormattedMessage id={'cookies.years'} />
                          </td>
                        </tr>
                        <tr>
                          <td>_gid</td>
                          <td>Google Ireland Limited</td>
                          <td>
                            <FormattedMessage id={'cookies.analysis'} />
                          </td>
                          <td>
                            24 <FormattedMessage id={'cookies.hours'} />
                          </td>
                        </tr>
                        <tr>
                          <td>_gat</td>
                          <td>Google Ireland Limited</td>
                          <td>
                            <FormattedMessage id={'cookies.analysis'} />
                          </td>
                          <td>
                            1 <FormattedMessage id={'cookies.minute'} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className={'modal-footer'}>
        <button className="button button-text" onClick={() => props.onSubmit(false)}>
          <FormattedMessage id="cookies.reject-everything" />
        </button>
        <button className="button button-text" onClick={() => props.onSubmit(enableAnalytics)}>
          <FormattedMessage id="cookies.confirm-selection" />
        </button>

        <Button variant="light" className="button button-contained" onClick={() => props.onSubmit(true)}>
          <FormattedMessage id="cookies.accept-all" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CookieConsentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CookieConsentModal;
