import React from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage, Link as LinkIntl, useIntl } from 'gatsby-plugin-intl';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookSquare, faTwitterSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import * as classes from './Footer.module.css';

import Logo from '../../images/logo.svg';

const Footer = (props) => {
  const intl = useIntl();

  return (
    <footer className={classes.Footer}>
      <Container fluid>
        <div className={classes.FooterRow}>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <div id="logo-container">
                <LinkIntl to="/index.html#landing">
                  <img src={Logo} alt="Bebensee IT Logo" title="Home" height="32px" />
                </LinkIntl>
              </div>
              <div className={classes.FooterLegal}>
                <span>© {new Date().getFullYear()} Bebensee IT GmbH</span>
                <br />
                <span>
                  <FormattedMessage id="footer.all_rights" />.
                </span>
              </div>
              {/*<div className={classes.FooterSocial}>*/}
              {/*  <FontAwesomeIcon icon={faFacebookSquare} />*/}
              {/*  <FontAwesomeIcon icon={faLinkedin} />*/}
              {/*  <FontAwesomeIcon icon={faTwitterSquare} />*/}
              {/*</div>*/}
            </Col>
            <Col xs={12} md={6} lg={3} className={classes.FooterAddress}>
              <p>Marie-Curie-Straße 1</p>
              <p>26129 Oldenburg</p>
              {intl.locale !== 'de' && <p>Germany</p>}
              <p>+49 441 559760-0</p>
              <p>
                &#107;&#111;&#110;&#116;&#97;&#107;&#116;&#64;&#98;&#101;&#98;&#101;&#110;&#115;&#101;&#101;&#46;&#105;&#116;
              </p>
            </Col>
            <Col xs={6} md={6} lg={3} className={classes.MobileWrapper}>
              <LinkIntl to="/index.html#landing" className={classes.FooterLink}>
                <FormattedMessage id="menu.home" />
              </LinkIntl>
              <LinkIntl to="/index.html#clients" className={classes.FooterLink}>
                <FormattedMessage id="menu.clients" />
              </LinkIntl>
              <LinkIntl to="/index.html#services" className={classes.FooterLink}>
                <FormattedMessage id="menu.services" />
              </LinkIntl>
              <LinkIntl to="/data-privacy/index.html" className={classes.FooterLink}>
                <FormattedMessage id="footer.privacy" />
              </LinkIntl>
              <Link to="/index.html#landing" onClick={props.onOpenPrivacySettings} className={classes.FooterLink}>
                <FormattedMessage id="footer.privacy_settings" />
              </Link>
            </Col>
            <Col xs={6} md={6} lg={3} className={classes.MobileWrapper}>
              <LinkIntl to="/about/index.html" className={classes.FooterLink}>
                <FormattedMessage id="menu.about" />
              </LinkIntl>
              <LinkIntl to="/index.html#contact" className={classes.FooterLink}>
                <FormattedMessage id="menu.contact" />
              </LinkIntl>
              <LinkIntl to="/blog/index.html" className={classes.FooterLink}>
                <FormattedMessage id="menu.blog" />
              </LinkIntl>
              <LinkIntl to="/legal/index.html" className={classes.FooterLink}>
                <FormattedMessage id="footer.imprint" />
              </LinkIntl>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  onOpenPrivacySettings: PropTypes.func,
};

export default Footer;
