import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import PropTypes from 'prop-types';

import * as classes from './Layout.module.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import CookieConsentModal from '../CookieConsentModal/CookieConsentModal';
import { useCookies } from 'react-cookie';

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const cookieName = 'gatsby-gdpr-google-analytics';
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies();

  const openPrivacySettings = () => {
    if (cookieName in cookies) {
      removeCookie(cookieName);
      removeCookie('_gid');
      removeCookie('_gat');
      removeCookie('_ga');
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };
  const submitCookies = (accept) => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie(cookieName, accept, { expires: oneYearFromNow });
    setShowCookieConsent(false);
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  useEffect(() => {
    if (cookieName in cookies) {
      setShowCookieConsent(false);
    }
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname === '/de/data-privacy/index.html' || location.pathname === '/en/data-privacy/index.html') {
      setShowCookieConsent(false);
    }
  }, [cookies, setShowCookieConsent]);

  return (
    <>
      <div className={classes.Page}>
        <Header siteTitle={data.site.siteMetadata.title} accentHeader={props.accentHeader} />
        <main>{props.children}</main>
      </div>
      <CookieConsentModal show={showCookieConsent} onSubmit={submitCookies} />
      <Footer onOpenPrivacySettings={openPrivacySettings} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  accentHeader: PropTypes.bool,
};

export default Layout;
